import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { alpha, Stack } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';

import { StyledButton, StyledText } from '@/components/lib';
import { InteractionTypeDDContainer, StyledInteractionsTypePopover } from './InteractionTypeDropDown.styles';

import { routes } from '@/helpers/routes';
import { ReadInteractionTypeSchema } from '@/helpers/services/api';
import { getStorageItem, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

import { constants } from './interactionTypes.constants';

interface InteractionTypeDropDownProps {
  noLabel?: boolean;
  disabled?: boolean;
  interactionType: string;
  appInteractionTypes?: ReadInteractionTypeSchema[];
  setInteractionType: React.Dispatch<React.SetStateAction<string>>;
}

const { label, types } = constants;

export const InteractionTypeDropDown = (props: InteractionTypeDropDownProps) => {
  const { disabled, noLabel, interactionType, appInteractionTypes, setInteractionType } = props;

  const { pathname } = useRouter();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const appStorage = getStorageItem(storageKeys.app);

  const availableTypesToUse = appInteractionTypes || appStorage?.interactionType?.options;

  const availableTypes = (() => {
    const availableTypesStorageNames = Array?.isArray(availableTypesToUse)
      ? availableTypesToUse?.filter(val => !!val?.is_active)?.map((type: { name: string }) => type?.name?.toLowerCase())
      : [];

    return availableTypesStorageNames?.length
      ? types?.filter(type => availableTypesStorageNames?.includes(type?.name.toLowerCase()))
      : types;
  })();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleChooseType = (type: string) => {
    setAnchorEl(null);
    setInteractionType(type);
    setStorageItem(storageKeys.app, {
      ...appStorage,
      interactionType: { options: availableTypesToUse, value: type }
    });
  };

  useEffect(() => {
    if (
      !interactionType ||
      !availableTypes?.find(type => type?.name?.toLowerCase() === interactionType?.toLowerCase())
    ) {
      setInteractionType(availableTypes[0]?.name);
      setStorageItem(storageKeys.app, {
        ...appStorage,
        interactionType: {
          value: availableTypes[0]?.name,
          options: availableTypesToUse
        }
      });
    }
  }, [interactionType, appInteractionTypes]);

  return (
    <>
      <InteractionTypeDDContainer disabled={disabled ? 'true' : ''}>
        {!noLabel && <StyledText text={label} />}
        <StyledButton
          disabled={disabled}
          endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
          label={
            pathname === routes.samples && interactionType?.length > 11
              ? `${interactionType?.slice(0, 9)}...`
              : interactionType
          }
          startIcon={
            pathname === routes.samples ? <></> : availableTypes.find(type => type?.name === interactionType)?.icon
          }
          onClick={handleOpen}
        />
      </InteractionTypeDDContainer>
      <StyledInteractionsTypePopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <Stack>
          {availableTypes?.map(type => (
            <StyledButton
              key={type.id}
              label={type.name}
              startIcon={type.icon}
              sx={{
                color:
                  type.name?.toLowerCase() === interactionType?.toLowerCase() ? `${type?.color} !important` : 'inherit',
                background:
                  type.name?.toLowerCase() === interactionType?.toLowerCase()
                    ? `${alpha(type?.color, 0.2)} !important`
                    : 'transparent'
              }}
              onClick={() => handleChooseType(type?.name)}
            />
          ))}
        </Stack>
      </StyledInteractionsTypePopover>
    </>
  );
};
