import React from 'react';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { HelpOutline, ThumbDownOutlined, ThumbUpOutlined, UpdateRounded } from '@mui/icons-material';

import { StyledText } from '@/components/lib';

import { AnnotationType, PropertyDefinitionSchema } from '@/helpers/services/api';

import { SelectedColumnType } from '../Samples.types';
import { sxErrorStyles, sxGreyStyles, sxLowGreyStyles, sxSuccessStyles } from './SamplesTable.styles';

import { constants } from './samplesTable.constants';

type Selected = string[];

export const selectMultiple = (
  event: React.MouseEvent<unknown>,
  id: string,
  selected: Selected,
  setSelected: React.Dispatch<React.SetStateAction<Selected>>
) => {
  event.stopPropagation();

  const selectedIndex = selected.indexOf(id);
  let newSelected: string[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  }

  setSelected(newSelected);
};

export const isSelected = (id: string, selected: Selected) => selected.indexOf(id) !== -1;

export function getAnnotation(annotation: AnnotationType | undefined | null, isEstimated?: boolean) {
  switch (annotation) {
    case 'good':
      return isEstimated ? (
        <>
          <ThumbUpOutlined sx={sxSuccessStyles} />
          <StyledText text="Est." type="bodyBold" sx={sxLowGreyStyles} />
        </>
      ) : (
        <ThumbUpIcon sx={sxSuccessStyles} />
      );

    case 'bad':
      return isEstimated ? (
        <>
          <ThumbDownOutlined sx={sxErrorStyles} />
          <StyledText text="Est." type="bodyBold" sx={sxLowGreyStyles} />
        </>
      ) : (
        <ThumbDownIcon sx={sxErrorStyles} />
      );

    case 'unknown':
      return <HelpOutline sx={sxGreyStyles} />;

    default:
      return <UpdateRounded sx={sxGreyStyles} />;
  }
}

const { inputKey, outputKey } = constants;

function foundProperty(propertylist: PropertyDefinitionSchema[], column: string) {
  return propertylist.find(e => e.property_name === column) ? column : propertylist[0]?.property_name;
}

export function saveCurrentSelectedColumn(
  setSelectedColumn: (value: React.SetStateAction<string>) => void,
  propertylist: PropertyDefinitionSchema[],
  column: string | undefined,
  renderedColumn: string,
  isPenTest: boolean,
  selectedColumns: SelectedColumnType[],
  setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumnType[]>>
) {
  const outputProperties = propertylist.filter(el => el.property_type === 'output');
  const inputProperties = propertylist.filter(el => el.property_type === 'input');
  const customProperties = propertylist.filter(el => el.property_type === 'custom');
  const garakProperties = propertylist.filter(el => el.property_type === 'garak');

  if (isPenTest && garakProperties?.length) {
    !selectedColumns.length &&
      setSelectedColumns([
        {
          selected: false,
          propertyName: garakProperties[0].property_name,
          propertyType: garakProperties[0].property_type
        }
      ]);
    setSelectedColumn(garakProperties[0]?.property_name);
    return;
  }

  if (!column) {
    setSelectedColumn(outputProperties[outputProperties.length - 3]?.property_name);
    return;
  }

  if (renderedColumn === inputKey) {
    setSelectedColumn(foundProperty(inputProperties, column));
    return;
  } else if (renderedColumn === outputKey) {
    setSelectedColumn(foundProperty(outputProperties, column));
    return;
  } else {
    const selectedColumn = customProperties.find(e => e.property_name === column)
      ? column
      : customProperties[0]?.property_name;

    if (selectedColumn) {
      setSelectedColumn(foundProperty(customProperties, column));
      return;
    } else {
      setSelectedColumn(inputProperties[0]?.property_name);
    }
  }
}
