export const capitalizeFirstLetter = (arg: string) => {
  if (arg?.length < 4) {
    return arg;
  }

  const firstLetterUppercase = arg.charAt(0).toUpperCase();
  const restOfTheStringLowercase = arg.slice(1).toLowerCase();

  return firstLetterUppercase + restOfTheStringLowercase;
};
