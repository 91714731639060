import React, { useEffect, useState } from 'react';

import { BoxProps, Stack } from '@mui/material';

import { StyledToolTip } from '@/components/lib';
import { SamplesAnnotationMenu } from './SamplesAnnotationMenu/SamplesAnnotationMenu';

import { StyledContainer } from './SamplesAnnotationCell.styles';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { getAnnotation } from '@/components/Samples/SamplesTable/SamplesTable.helpers';
import { AnnotationSchema, AnnotationType, updateAnnotationsApiV1AnnotationsPut } from '@/helpers/services/api';

interface AnnotationCellProps extends BoxProps {
  interactionId: string;
  interactionVersionId?: number;
  nonEditable?: boolean;
  disableTooltip?: boolean;
  annotation: AnnotationSchema | null;
  estAnnotation?: AnnotationSchema | null;
  updateAnnotation?: (
    interactionId: string,
    annotation: string,
    reason: string,
    isEstimated?: boolean,
    deleteAnnotation?: boolean
  ) => void;
}

export const AnnotationCell = (props: AnnotationCellProps) => {
  const {
    annotation,
    interactionId,
    interactionVersionId,
    nonEditable,
    disableTooltip,
    estAnnotation,
    updateAnnotation,
    ...otherProps
  } = props;

  const [reason, setReason] = useState(annotation?.reason ?? '');
  const [annotationVal, setAnnotationVal] = useState<AnnotationType | undefined>(annotation?.value as AnnotationType);
  const [isEstimated, setIsEstimated] = useState(annotation?.is_estimated);
  const [anchorElRootMenu, setAnchorElRootMenu] = useState<HTMLElement | null>(null);

  const { versionId } = getAppStorage();

  const estimatedAnnotation = !annotation?.is_estimated ? estAnnotation : undefined;

  const handleOpenRootMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!nonEditable) {
      event.stopPropagation();
      setAnchorElRootMenu(event.currentTarget);
    }
  };

  const handleModifyAnnotationId = (annotation: string | null, isRevert?: boolean) => {
    setIsEstimated(false);

    updateAnnotationsApiV1AnnotationsPut({
      application_version_id: interactionVersionId || versionId,
      user_interaction_id: interactionId,
      value: isRevert ? undefined : (annotation as AnnotationType),
      reason: isRevert ? undefined : reason,
      revert_to_estimated: isRevert
    });

    if (isRevert && annotation === null && updateAnnotation) {
      const finalReason = isRevert ? `${estAnnotation?.reason}` : reason;
      const finalAnnotation = `${estAnnotation?.value}`;

      updateAnnotation(interactionId, finalAnnotation, finalReason, isRevert, true);
      setAnnotationVal(undefined);
    } else if (annotation && updateAnnotation) {
      const finalReason = isRevert ? `${estAnnotation?.reason}` : reason;
      const finalAnnotation = isRevert ? `${estAnnotation?.value}` : annotation;
      updateAnnotation(interactionId, finalAnnotation, finalReason, isRevert);
    }
  };

  const handleCloseRootMenu = (event: { stopPropagation?: () => void }, isRevert?: boolean) => {
    event?.stopPropagation && event?.stopPropagation();
    setAnchorElRootMenu(null);

    if (!isRevert && reason !== annotation?.reason && annotationVal) {
      handleModifyAnnotationId(annotationVal);
    }
  };

  useEffect(() => {
    setReason(annotation?.reason ?? '');
    setIsEstimated(annotation?.is_estimated);
    setAnnotationVal(annotation?.value as AnnotationType);
  }, [annotation?.value, annotation?.is_estimated, annotation?.reason]);

  return (
    <StyledContainer onClick={handleOpenRootMenu} {...otherProps}>
      <StyledToolTip text={disableTooltip ? '' : (annotation?.reason as string)}>
        <Stack>{getAnnotation(annotationVal, isEstimated)}</Stack>
      </StyledToolTip>
      <SamplesAnnotationMenu
        reason={reason}
        isEstimated={isEstimated}
        open={!!anchorElRootMenu}
        anchorEl={anchorElRootMenu}
        annotationVal={annotationVal}
        estAnnotation={estimatedAnnotation}
        setReason={setReason}
        setAnnotationVal={setAnnotationVal}
        onClose={e => handleCloseRootMenu(e)}
        handleCloseRootMenu={handleCloseRootMenu}
        handleModifyAnnotationId={handleModifyAnnotationId}
      />
    </StyledContainer>
  );
};
