import { keyframes } from '@emotion/react';

export const shakingAnimation = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(0.8deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-0.8deg); }
  100% { transform: rotate(0deg); }
`;

export const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
