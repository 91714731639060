import React from 'react';

import { MenuProps } from '@mui/material';

import { SamplesAnnotationEst } from './SamplesAnnotationEst/SamplesAnnotationEst';

import { StyledInput, StyledText } from '@/components/lib';
import { StyledMenuItem, StyledRootMenu } from '../SamplesAnnotationCell.styles';
import { StyledRowContainer } from '../SamplesAnnotationCell.styles';

import { getAnnotation } from '@/components/Samples/SamplesTable/SamplesTable.helpers';

import { AnnotationSchema, AnnotationType } from '@/helpers/services/api';
import { capitalizeFirstLetter } from '@/helpers/utils/capitalizeFirstLetter';

import { constants } from '../samplesAnnotationCell.constants';

interface SamplesAnnotationMenuProps extends MenuProps {
  reason: string;
  isEstimated: boolean | undefined;
  annotationVal: AnnotationType | undefined;
  estAnnotation?: AnnotationSchema | null;
  setReason: (reason: string) => void;
  handleModifyAnnotationId: (annotation: string | null, isRevert?: boolean) => void;
  handleCloseRootMenu: (e: { stopPropagation?: () => void }, isRevert?: boolean) => void;
  setAnnotationVal: (annotation: AnnotationType | undefined) => void;
}

const { options, inputPlaceHolder } = constants.menu;

export const SamplesAnnotationMenu = (props: SamplesAnnotationMenuProps) => {
  const {
    reason,
    isEstimated,
    annotationVal,
    estAnnotation,
    setReason,
    setAnnotationVal,
    handleCloseRootMenu,
    handleModifyAnnotationId,
    ...otherProps
  } = props;

  const isSelected = (annotation: string) => annotation === annotationVal && !isEstimated;

  const handleSelect = async (event: React.SyntheticEvent, annotation: AnnotationType) => {
    event.stopPropagation();
    setAnnotationVal(annotation);
    handleModifyAnnotationId(annotation);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.code === 'Enter' && handleCloseRootMenu(event);
    event.code === 'KeyG' && event.stopPropagation();
  };

  return (
    <StyledRootMenu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...otherProps}
    >
      <StyledRowContainer>
        {options.map(annotation => (
          <StyledMenuItem
            key={annotation}
            onClick={e => handleSelect(e, annotation as AnnotationType)}
            selected={isSelected(annotation)}
          >
            {getAnnotation(annotation as AnnotationType)}
            <StyledText text={capitalizeFirstLetter(annotation)} type="tinyBold" />
          </StyledMenuItem>
        ))}
      </StyledRowContainer>
      <StyledInput
        value={reason}
        placeholder={inputPlaceHolder}
        onChange={e => setReason(e.target.value)}
        onKeyDown={e => handleKeyDown(e)}
      />
      <SamplesAnnotationEst
        estAnnotation={estAnnotation}
        annotationVal={isEstimated ? undefined : annotationVal}
        handleCloseRootMenu={handleCloseRootMenu}
        handleModifyAnnotationId={handleModifyAnnotationId}
      />
    </StyledRootMenu>
  );
};
