import { Box, styled } from '@mui/material';

import { StyledText } from '@/components/lib';

export const StyledInOutIcon = styled(StyledText)(({ theme }) => ({
  padding: '0 4px',
  height: '21px',
  width: 'auto',
  border: `2px solid ${theme.palette.grey[300]}`,
  color: theme.palette.grey[500],
  borderRadius: '5px',
  overflow: 'unset',
  fontSize: '10px !important',
  fontWeight: '700',
  textAlign: 'center'
}));

export const StyledInOutContainer = styled(Box)(({ theme }) => ({
  height: '20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.MuiSvgIcon-root': { fontSize: '20px', color: theme.palette.grey[500] }
}));
