import React, { JSX } from 'react';

import {
  // TaskRounded,
  HelpRounded,
  CategoryRounded,
  SummarizeRounded,
  // TableChartRounded,
  AutoAwesomeRounded,
  ConstructionRounded
} from '@mui/icons-material';

export interface InteractionTypeTS {
  name: string;
  id: number;
  icon: JSX.Element;
  color: string;
}

export const constants = {
  label: 'Interaction Type',
  types: [
    { name: 'Q&A', id: 1, icon: <HelpRounded />, color: '#C23EC0' },
    // { name: 'Retrieval', id: 2, icon: <TaskRounded />, color: '#DD268D' },
    { name: 'Other', id: 3, icon: <ConstructionRounded />, color: '#00B2C2' },
    { name: 'Generation', id: 4, icon: <AutoAwesomeRounded />, color: '#367BF4' },
    { name: 'Summarization', id: 5, icon: <SummarizeRounded />, color: '#02A5E6' },
    { name: 'Classification', id: 6, icon: <CategoryRounded />, color: '#9159F7' }
    // { name: 'SQL Builder', id: 7, icon: <TableChartRounded />, color: '#00B2C2' }
  ]
};
