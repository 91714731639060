import { Box, Menu, MenuItem, styled } from '@mui/material';

export const StyledContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '44px'
});

export const StyledRowContainer = styled(Box)({
  margin: '0 auto 16px',
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'row'
});

export const StyledRootMenu = styled(Menu)(({ theme }) => ({
  marginTop: '9px',

  '& .MuiPaper-root': {
    width: '290px',
    padding: '16px',
    overflow: 'visible',
    borderRadius: '10px',
    justifyContent: 'center',
    boxShadow: `2px 2px 30px -10px ${theme.palette.grey[400]}`
  }
}));

interface styledMenuItemProps {
  selected?: boolean;
}

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: prop => prop !== 'selected'
})<styledMenuItemProps>(({ selected, theme }) => ({
  gap: '12px',
  padding: '2px 16px',
  position: 'relative',
  flexDirection: 'column',
  background: selected ? theme.palette.grey[200] : 'transparent',

  '& svg': {
    color: theme.palette.primary.main
  },

  '&:not(:last-child)': {
    borderRight: `2px solid ${theme.palette.grey[200]}`
  }
}));
