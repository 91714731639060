import React from 'react';

import { StyledToolTip } from '@/components/lib';
import { StyledInOutContainer, StyledInOutIcon } from './InOutIcon.styles';

import { PropertyType } from '@/helpers/services/api';

import { constants } from './inOutIcon.constants';

interface InOutIconProps {
  kind?: PropertyType;
  margin?: string;
  noTooltip?: boolean;
}

export const InOutIcon = ({ kind, margin = '0', noTooltip }: InOutIconProps) => {
  const { input, inputTooltip, output, outputTooltip, custom, customTooltip, llm, llmTooltip } = constants;

  if (!kind) {
    return <></>;
  }

  const iconData = () => {
    switch (kind) {
      case PropertyType['input']:
        return { text: input, tooltip: inputTooltip };
      case PropertyType['output']:
        return { text: output, tooltip: outputTooltip };
      case PropertyType['custom']:
        return { text: custom, tooltip: customTooltip };
      case PropertyType['llm']:
        return { text: llm, tooltip: llmTooltip };
      default:
        return { text: 'Detector', tooltip: '' };
    }
  };

  return (
    <>
      {noTooltip ? (
        <StyledInOutContainer margin={margin}>
          <StyledInOutIcon text={iconData().text} />
        </StyledInOutContainer>
      ) : (
        <StyledToolTip text={iconData().tooltip} placement="right">
          <StyledInOutContainer margin={margin}>
            <StyledInOutIcon text={iconData().text} />
          </StyledInOutContainer>
        </StyledToolTip>
      )}
    </>
  );
};
