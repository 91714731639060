export const constants = {
  similarityLinkLabel: 'Similar Response',
  menu: { options: ['good', 'bad', 'unknown'], inputPlaceHolder: 'Type Reason...' },
  estAnnotation: {
    title: 'Estimated Annotation',
    noReason: 'No reason provided',
    buttonLabel: 'Revert back to estimated',
    calculating: 'Estimated annotation is being calculated...'
  },
  deleteButtonLabel: 'Delete User Annotation'
};
