import React from 'react';

import { routes } from '@/helpers/routes';

import { Popover, PopoverProps, Stack, styled } from '@mui/material';

export const InteractionTypeDDContainer = styled(Stack)<{ disabled?: string }>(({ theme, disabled }) => ({
  borderRadius: '4px',
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'auto',
  alignItems: window?.location?.pathname === routes.samples ? 'left' : 'center',
  gap: window?.location?.pathname === routes.samples ? '2px' : '16px',
  padding: window?.location?.pathname === routes.samples ? ' 8px 12px' : 'none',
  flexDirection: window?.location?.pathname === routes.samples ? 'column' : 'row',
  border: window?.location?.pathname === routes.samples ? `2px solid ${theme.palette.grey[200]}` : 'none',
  width: window?.location?.pathname === routes.samples ? '178px' : '360px',

  '.Mui-disabled': { background: `${theme?.palette?.common?.white} !important` },
  '.MuiButtonBase-root': {
    gap: '4px',
    fontWeight: 500,
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '2px 0 0 0',
    borderRadius: '6px',
    padding: '8px 12px',
    whiteSpace: 'nowrap',
    justifyContent: 'left',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    color: theme.palette.grey?.[500],
    border: `1px solid ${theme.palette.grey?.[400]}`,
    width: window?.location?.pathname === routes.samples ? '144px' : '225px',

    '.MuiButton-endIcon': { marginLeft: 'auto' },

    ':hover': { border: `1px solid ${theme.palette.grey?.[400]}`, opacity: 0.7 }
  }
}));

export const StyledInteractionsTypePopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    slotProps={{
      paper: {
        style: {
          padding: '6px',
          height: 'auto',
          overflow: 'auto',
          maxHeight: '230px',
          borderRadius: '10px'
        }
      }
    }}
    {...props}
  />
))(({ theme }) => ({
  '.MuiButtonBase-root': {
    border: 'none',
    padding: '8px',
    width: '200px',
    boxShadow: 'none',
    textAlign: 'left',
    borderRadius: '2px',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    color: theme.palette.grey?.[400]
  }
}));
